<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Terrassenüberdachung / Lewens / Murano Integrale <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Murano Integrale - Glasdach mit Markise
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full lg:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>

        <div class="col-span-full lg:col-span-1">
          <h2
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            bestens geschützt mit integriertem Sonnenschutz
          </h2>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Murano Integrale bietet variablen Schutz vor Regen, Temperatur und
            Sonnenlicht. Mit einer in das Wandprofil integrierten Markise
            vereint dieses Modell den klaren Durchblick und Wetterschutz eines
            Glasdachs mit den Vorzügen einer hochwertigen Markise als flexibel
            einstellbarer Sonnenschutz. Auch für dieses groß dimensionierte
            Glasdach stehen diverse Typen und Bauformen mit vorn und seitlich
            bündigen oder eingerückten Pfosten zur Wahl.<br /><br />
          </p>
        </div>
      </div>
      <div class="p-4">
        <div class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</div>
        <tabs :mode="mode">
          <tab title="Typen">
            <div class="text-lg">
              <span class="font-bold"
                >Erhältlich in verschiedene Produktvarianten:
              </span>
              <br />
              <ul class="flex flex-col sm:flex-row">
                <li class="px-2 flex flex-col w-2/3">
                  <div class="w-1/3">
                    <img
                      src="../assets/img/lewens/integrale/lew-murano-int-b-typ-ganz-fs-orange-uli.webp"
                      style="min-width: 10rem"
                      alt="murano integrale typ buendig"
                    />
                  </div>
                  <div class="lg:pl-16">Murano Intergrale Typ Bündig (B)</div>
                </li>
                <li class="px-2 flex flex-col w-2/3">
                  <div class="w-1/3">
                    <img
                      src="../assets/img/lewens/integrale/lew-murano-int-ue-typ-ganz-orange-uli.webp"
                      style="min-width: 10rem"
                      alt="murano integrale typ ueberstand"
                    />
                  </div>
                  <div class="lg:pl-16">Murano Integrale Typ Überstand (Ü)</div>
                </li>
              </ul>
            </div>
          </tab>
          <tab title="Maßen">
            <p class="text-lg">
              <span class="font-bold">Breite:</span> <br />
              max. 700 cm - 1-teilig (Einzelfeld)<br />
              <span class="font-bold">Ausfall/Tiefe:</span> <br />
              max. 500 cm <br />
              <span class="font-bold">Antrieb:</span> <br />
              Motorantrieb <br />
              <span class="font-bold">Markisentücher:</span> <br />
              Marken-Acryl <br />
              Marken-Polyester (Option) <br />
              <span class="font-bold">Verglasung:</span> <br />
              für VSG 8 mm <br />
              für VSG 10 mm <br />
              für Vsg 12 mm <br />
              für Doppelstegplatten 16 mm
            </p>
          </tab>
          <tab title="Zertifizierung">
            <div class="text-lg">
              <span class="font-bold">Zertifizierung:</span> <br />
              <div class="flex flex-col">
                <div class="flex flex-row">
                  <div>
                    <img
                      src="../assets/madeingermany.webp"
                      alt="made in germany siegel"
                    />
                  </div>
                  <div class="w-full pl-1">
                    Hergestellt und montiert in Deutschland
                  </div>
                </div>
                <div class="flex flex-row">
                  <div><img src="../assets/ce-norm.webp" alt="ce-norm" /></div>
                  <div class="w-full pl-1">
                    Qualität und Technik nach CE-Norm
                  </div>
                </div>
                <div class="flex flex-row">
                  <div>
                    <img src="../assets/tuev.webp" alt="tuev geprueft" />
                  </div>
                  <div class="w-full pl-1">TÜV-geprüfte Sicherheit</div>
                </div>
              </div>
            </div>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/lewens/integrale/lew-murano-int-ue-ganz-haus-uli.webp"),
          alt: "terrassendach lewens integrale",
        },
        {
          image: require("@/assets/img/lewens/integrale/modelle-web-rd-20.webp"),
          alt: "terrassendach lewens integrale detailsansicht",
        },
        {
          image: require("@/assets/img/lewens/integrale/lew-murano-int-b-detail-uli6.webp"),
          alt: "terrassendach integrale ausfahr details",
        },
        {
          image: require("@/assets/img/lewens/integrale/lew-murano-int-ue-detail-uli.webp"),
          alt: "terrassendach lewens integrale ausfahr details",
        },
        {
          image: require("@/assets/img/lewens/integrale/lew-murano-int-ue-detail-rinne-uli.webp"),
          alt: "terrassendach integrale details",
        },
        {
          image: require("@/assets/img/lewens/integrale/lew-murano-int-b-detail-rinne-uli.webp"),
          alt: "terrassendach integrale details",
        },
      ],
    };
  },
};
</script>
